<template>
    <div class="gywm">
        <ContactUs></ContactUs>
        <div class="gywm_m">
            <div class="part_header">
                <router-link to="/">首页</router-link> > 关于我们
            </div>
            <div class="gywm_content">
                <p style="text-indent:0;height:30px;line-height:30px;margin-top:20px;">非常感谢您访问瞻世趣官方网站了解我们的信息。</p>
                <h3>关于瞻世趣</h3>
                <p> 瞻世趣是一家专门提供彩票营销解决方案并具有实施能力的企业，核心成员包含彩票运营专家、市场营销专家、前彩票机构负责人，从事彩票相关工作均超过十年以上，能够准确把握国内彩票政策环境、市场发展节奏，具备运用互联网思维制定全局性商业计划的能力。
                </p>
                <p>随着2015年政策环境变化，互联网彩票代销时代已经终结，新的彩票行业格局正在酝酿，凭借瞻世趣的彩票专业能力，我们期望与合作伙伴共同开拓未来的中国彩票市场。</p>
                <h3>瞻世趣优势</h3>
                <ul>
                    <li>拥有成熟的彩票电子商务运营经验</li>
                    <li>精通国内外彩票、博彩游戏玩法规则、运营流程</li>
                    <li>充分了解彩票玩法设计、系统开发、市场营销、财务结算、政策法规、传统渠道销售、新渠道销售等彩票营销主要环节业务模式</li>
                    <li>与彩票机构、行业企业建立了长期稳固的业务互信合作关系，拥有极强的资源调动和整合能力</li>
                    <li>做为彩票行业的规则制定者之一在彩票行业中极具影响力</li>
                    <li>能够根据市场环境变化不断设计具有前瞻性的解决方案</li>
                </ul>
                <h3>企业愿景</h3>
                <ul>
                    <li>立足于中国成为世界级的一流专业彩票运营企业</li>
                </ul>
                <h3>企业使命</h3>
                <ul>
                    <li>为彩票销售、管理机构提供并实施创新性的专业解决方案</li>
                    <li>为彩票行业企业提供新的市场机会</li>
                    <li>为彩票玩家提供便利的、娱乐化、高效率的投注环境</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import ContactUs from "@/components/common/contactus.vue";
    export default {
        name: 'Gywm',
        components: {
            ContactUs
        }
    }
</script>
<style lang="less" scoped>
    .gywm {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;

        .gywm_m {
            width: 700px;
            margin-bottom: 100px;
            .gywm_content {
                color: rgb(127, 127, 127);
                font-size: 16px;

                p {
                    line-height: 1.75em;
                    text-indent: 2em;

                }

                li {
                    line-height: 1.5;
                    margin-left: 20px;
                }

                h3 {
                    color: rgb(44, 90, 168);
                    margin-top: 15px;
                }
            }
        }
    }
</style>